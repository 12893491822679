<template>
	<div v-if="shop" class="popup full">
		<div class="popup-container">
			<!-- TopContainer -->
			<div class="top-container p-0">
				<!-- CloseButton -->
				<n-close-button />
				<!-- CartMethod -->
				<div>
					<h4 class="mb-1">{{ cartMethod }}</h4>
					<div class="subtitle font-weight-500 mb-3">{{ cart.type == 'ASAP' ? '* 請儘早前往餐廳取餐，店家將為您優先準備' : '* 請記得在預約時間時前往取餐' }}</div>
				</div>
			</div>
			<div class="address-container relative-element" v-if="cart.method == 'DELIVERY'">
				<div class="bg-white pt-3">
					<div class="input-address-container">
						<div class="icon p-3"><img src="@/assets/icons/ic-search-black.svg" width="24" height="24" /></div>
						<input class="address" v-model="cart.customer.address" autofocus="autofocus" placeholder="請輸入地址" />
						<button class="clear-address-btn" @click="cart.customer.address = ''" style="right: 16px;">
							<span class="font-17 font-weight-500 text-deep-blue">清除</span>
						</button>
					</div>
				</div>
			</div>
			<div class="font-21 font-weight-500 text-warm-grey mt-3 mb-2">預約時間</div>
			<div class="bg-white-fa border-radius-8">
				<!-- CartType -->
				<div class="form">
					<label v-for="option in typeOptions" :key="option.value" class="radio-container line-height-15">
						<input v-model="cart.type" :value="option.value" type="radio" @click="cart.type = option.value" />
						<div class="option-layout">
							<div class="flex-element center between">
								<span class="font-17 text-black font-weight-500 line-height-12">{{ option.label }}</span>
								<span class="font-17 text-warm-grey font-weight-500" v-if="option.value === 'ASAP'">預計 {{ shop.isBusy ? shop.busyTime : shop.readyTime }} 分鐘</span>
							</div>
						</div>
					</label>
				</div>
				<!-- CartReservationTime -->
				<div v-if="cart.type === 'RESERVE'" class="form px-3 pb-3">
					<!-- DateInput -->
					<div class="select-container mb-2">
						<select class="font-19 white" v-model="reservation.date">
							<option v-for="date in dateOptions" :key="date.value" :value="date.value">{{ date.label }}</option>
						</select>
					</div>
					<!-- TimeInput -->
					<div class="select-container mb-2">
						<select class="font-19 white" v-model="reservation.time">
							<option v-for="time in timeOptions" :key="time.value" :value="time.value">{{ time.label }}</option>
						</select>
					</div>
				</div>
			</div>
			<!-- SubmitButton -->
			<div class="fixed-bottom-btn relative">
				<div v-if="cart.method == 'DELIVERY'">
					<button v-if="cart.customer.address.length > 0" @click="onSubmit" class="btn primary-btn flex-center mt-3" :disabled="!validateSubmit">確認{{ cartMethod }}資訊</button>
				</div>
				<div v-else>
					<button @click="onSubmit" class="btn primary-btn flex-center mt-3" :disabled="!validateSubmit">確認{{ cartMethod }}資訊</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { renderTimeOptions } from '@/utils';
import moment from 'moment';

export default {
	data() {
		return {
			reservation: {
				date: '',
				time: '',
			},
		};
	},

	mounted() {
		if (this.cart.reservationAt) {
			this.reservation.date = moment.unix(this.cart.reservationAt).format('MM/DD');
			this.reservation.time = moment.unix(this.cart.reservationAt).format('HH:mm');
		} else {
			this.reservation.date = this.dateOptions[0].value;
			this.reservation.time = this.timeOptions[0].value;
		}
	},

	computed: {
		...mapState({
			shop: state => state.shop,
			cart: state => state.cart,
		}),

		// 計算訂單方式
		cartMethod() {
			switch (this.cart.method) {
				case 'PICKUP':
					return '自取';
				case 'DELIVERY':
					return '外送';
				default:
					return '';
			}
		},

		typeOptions() {
			let today = moment().isoWeekday() - 1;
			let todayOpeningHours = this.shop.openingHours ? this.shop.openingHours[today] : null;
			if (!todayOpeningHours && todayOpeningHours.length < 1) return [{ value: 'RESERVE', label: '指定時間取餐' }];
			let flag = false;
			todayOpeningHours.forEach(time => {
				if (moment().isBetween(moment(time.start, 'HH:mm'), moment(time.end, 'HH:mm')) && this.shop.id !== 'rw8M6gCBVgn6wh6IOETV') {
					flag = true;
				}
			});
			return flag
				? [
						{ value: 'ASAP', label: '馬上取餐' },
						{ value: 'RESERVE', label: '指定時間取餐' },
				  ]
				: [{ value: 'RESERVE', label: '指定時間取餐' }];
		},

		// 計算出店家預設的可選取的日期 (包含今日)
		dateOptions() {
			let options = [];
			for (let i = 0; i <= this.shop.reservationDays; i++) {
				options.push({
					label: moment()
						.add(i, 'days')
						.format('MM/DD'),
					value: moment()
						.add(i, 'days')
						.format('MM/DD'),
				});
			}
			return options;
		},

		// 計算出店家預設的可選取的時間
		timeOptions() {
			if (!this.shop.openingHours) return [];
			return renderTimeOptions(this.reservation.date, this.shop);
		},

		validateSubmit() {
			if (this.cart.type == 'ASAP') return true;
			return this.reservation.date && this.reservation.time ? true : false;
		},
	},

	methods: {
		async onSubmit() {
			if (this.cart.type == 'RESERVE') {
				const reservationTime = moment();
				reservationTime.set({
					month: Number(this.reservation.date.substring(0, 2)) - 1,
					date: Number(this.reservation.date.substring(3, 5)),
					hour: Number(this.reservation.time.substring(0, 2)),
					minute: Number(this.reservation.time.substring(3, 5)),
					second: 0,
				});
				this.cart.reservationAt = reservationTime.format('X');
				this.$router.go(-1);
			} else {
				this.cart.reservationAt = null;
				this.$router.go(-1);
			}
		},
	},
};
</script>
